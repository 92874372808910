import React from "react"

import Img from "../common/Img"
import RichText from "../common/RichText"
import s from "./ProductCard.module.scss"

export default function ProductCard({ name, imageUrl }) {
  return (
    <figure className={s.productCard}>
      <div className={s.imageHolder}>
        {imageUrl ? (
          <Img
            alt={name}
            className={s.productImage}
            objectFit="contain"
            objectPosition="center"
            placeholderHidden
            src={imageUrl}
          />
        ) : null}
      </div>
      <figcaption className={s.caption}>
        <div className={s.name}>
          <div>
            <RichText>{name}</RichText>
          </div>
        </div>
      </figcaption>
    </figure>
  )
}
