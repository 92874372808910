import classNames from "classnames"
import React, { useContext } from "react"

import { BackIcon } from "../common/Icons"
import { LocaleContext } from "../Layout"
import s from "./BackButton.module.scss"

export default function BackButton({ className, onClick }) {
  const { ui } = useContext(LocaleContext)

  return (
    <div
      className={classNames(s.backButton, className)}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <BackIcon className={s.icon} />
      <div className={s.text}>{ui.backButton}</div>
    </div>
  )
}
