import { graphql } from "gatsby"
import React from "react"

import Products from "../../components/Cards/Products"
import Hero, {
  colors,
  variations as heroVariations,
  titleColors,
} from "../../components/Hero/Hero"
import Layout from "../../components/Layout"
import MenuNav from "../../components/MenuNav/MenuNav"
import SEO from "../../components/seo"
import {
  selectProductGroups,
  selectProducts,
  selectTranslations,
} from "../../graphql/selectors"
import s from "./ProductGroupPage.module.scss"

export default function ProductGroupPage(props) {
  const { data, pageContext } = props
  const translations = selectTranslations(data)
  const {
    langCode,
    locale,
    localePath,
    productGroup,
    pagePathsByLangCode,
  } = pageContext
  const products = selectProducts(data).filter(
    (product) => product.groups.indexOf(productGroup.id) >= 0
  )
  const heroBgColor = setHeroBackgroundColor()
  const heroTitleColor = setHeroTitleColor()

  function setHeroBackgroundColor() {
    switch (productGroup.id) {
      case "chicken-burgers":
        return colors.crusta
      case "beverages":
        return colors.grenadier
      case "sides-and-snacks":
        return colors.grenadier
      case "plant-based-salads-and-wraps":
        return colors.green
      case "desserts":
        return colors.orange
      default:
        return colors.dark
    }
  }

  function setHeroTitleColor() {
    switch (productGroup.id) {
      case "desserts":
        return titleColors.dark
      default:
        return titleColors.bright
    }
  }

  return (
    <Layout
      className={s.productsGroupPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO
        description={productGroup.description}
        lang={langCode}
        title={productGroup.name}
      />
      <MenuNav className={s.menuNav} items={selectProductGroups(data)} />
      <Hero
        backUrl={"/menu/"}
        color={heroBgColor}
        localizedLink={true}
        title={productGroup.name}
        titleColor={heroTitleColor}
        variation={heroVariations.productMenu}
      />
      <div className={s.content}>
        <Products
          locale={locale}
          productGroupSlug={productGroup.slug}
          products={products}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProductGroupPage($locale: String) {
    ...LayoutFragment
    ...ProductFragment
    ...TranslationsFragment
  }
`
