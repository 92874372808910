import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"

import BackButton from "../BackButton/BackButton"
import LocalizedLink from "../common/LocalizedLink"
import s from "./Hero.module.scss"

export const variations = {
  compact: "compact",
  default: "default",
  narrow: "narrow",
  wide: "wide",
  wideBottomPadding: "wideBottomPadding",
  productMenu: "productMenu",
  product: "product",
  notFound: "notFound",
}
export const colors = {
  crusta: "crusta",
  dark: "dark",
  green: "green",
  grenadier: "grenadier",
  orange: "orange",
}

export const titleColors = {
  accent: "accent",
  bright: "bright",
  dark: "dark",
}

export default function Hero({
  backUrl = null,
  children,
  color = colors.crusta,
  description,
  localizedLink = false,
  variation = variations.default,
  title,
  titleColor = titleColors.bright,
}) {
  return (
    <div className={classNames(s.hero, s[variation], s[color])}>
      {backUrl ? (
        <header className={s.header}>
          {localizedLink ? (
            <LocalizedLink className={s.backButtonHolder} to={backUrl}>
              <BackButton />
            </LocalizedLink>
          ) : (
            <Link className={s.backButtonHolder} to={backUrl}>
              <BackButton />
            </Link>
          )}
        </header>
      ) : null}
      <div className={s.content}>
        <div className={classNames(s.titleHolder, s[titleColor])}>
          <h1 className={classNames(s.title, s[variation])}>{title}</h1>
        </div>
        {description ? (
          <div className={s.description}>
            <p>{description}</p>
          </div>
        ) : null}
        {children ? <div className={s.children}>{children}</div> : null}
      </div>
    </div>
  )
}
