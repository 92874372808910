import classNames from "classnames"
import React from "react"

import LocalizedProductLink from "../common/LocalizedProductLink"
import ProductCard from "./ProductCard"
import s from "./Products.module.scss"

export const contentPosition = {
  default: "default",
  center: "center",
}

export default function Products({
  locale,
  products,
  productGroupSlug,
  position = contentPosition.default,
}) {
  return (
    <div className={classNames(s.products, s[position])}>
      {products.map((product) => {
        if (product.productIsHidden === true) {
          return null
        }

        return (
          <div className={s.product} key={product.id}>
            <LocalizedProductLink
              className={s.productLink}
              productGroupSlug={productGroupSlug}
              productSlug={product.slug}
            >
              <ProductCard {...product} locale={locale} />
            </LocalizedProductLink>
          </div>
        )
      })}
    </div>
  )
}
