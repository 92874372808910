import classNames from "classnames"
import React, { useLayoutEffect, useRef, useState } from "react"
import { useEffectOnce } from "react-use"

import { ArrowIcon } from "../common/Icons"
import Img from "../common/Img"
import LocalizedProductLink from "../common/LocalizedProductLink"
import s from "./MenuNav.module.scss"

export default function MenuNav({ className, items }) {
  const [leftEdgeReached, setLeftEdgeReached] = useState(true)
  const [rightEdgeReached, setRightEdgeReached] = useState(true)
  const [itemsNotFits, setItemsNotFits] = useState(false)
  const scrollContainerRef = useRef(null)
  const itemsWrapperRef = useRef(null)

  useEffectOnce(() => {
    checkItemsNotFit()
    window.addEventListener("resize", checkItemsNotFit)

    return () => window.removeEventListener("resize", checkItemsNotFit)
  })

  useLayoutEffect(handleScroll)

  return (
    <div
      className={classNames(s.menuNav, className, {
        [s.itemsNotFits]: itemsNotFits,
      })}
    >
      <div
        className={classNames(s.paddle, s.paddleLeft, {
          [s.active]: !leftEdgeReached,
        })}
        onClick={scrollLeft}
        onKeyDown={scrollLeft}
        role="button"
        tabIndex="0"
      >
        <ArrowIcon className={s.arrowLeft} />
      </div>
      <div className={s.track} onScroll={handleScroll} ref={scrollContainerRef}>
        <div className={s.itemsWrapper} ref={itemsWrapperRef}>
          {items.map(({ thumbnailUrl, name, slug }) => (
            <MenuNavItem
              key={slug}
              name={name}
              slug={slug}
              thumbnailUrl={thumbnailUrl}
            />
          ))}
        </div>
      </div>
      <div
        className={classNames(s.paddle, s.paddleRight, {
          [s.active]: !rightEdgeReached,
        })}
        onClick={scrollRight}
        onKeyDown={scrollRight}
        role="button"
        tabIndex="0"
      >
        <ArrowIcon className={s.arrowRight} />
      </div>
    </div>
  )

  function checkItemsNotFit() {
    const itemsWidth = itemsWrapperRef.current.scrollWidth
    const windowWidth = window.innerWidth

    itemsWidth > windowWidth ? setItemsNotFits(true) : setItemsNotFits(false)
  }

  function handleScroll() {
    const scrollLeft = scrollContainerRef.current.scrollLeft
    const itemsWidth = itemsWrapperRef.current.scrollWidth
    const windowWidth = window.innerWidth

    if (itemsWidth < windowWidth) {
      setLeftEdgeReached(false)
      setRightEdgeReached(false)
    } else {
      setLeftEdgeReached(scrollLeft === 0)
      setRightEdgeReached(itemsWidth - windowWidth - scrollLeft <= 0)
    }
  }

  function scrollLeft() {
    scrollContainerRef.current.scrollBy({
      left: -(window.innerWidth * 0.7),
      behavior: "smooth",
    })
  }

  function scrollRight() {
    scrollContainerRef.current.scrollBy({
      left: window.innerWidth * 0.7,
      behavior: "smooth",
    })
  }
}

function MenuNavItem({ name, slug, thumbnailUrl }) {
  return (
    <LocalizedProductLink
      activeClassName={s.active}
      className={s.item}
      key={slug}
      partiallyActive={true}
      productGroupSlug={slug}
    >
      <Img
        alt={name}
        className={s.itemImage}
        objectFit="fill"
        objectPosition="50% 50%"
        placeholderHidden
        src={thumbnailUrl}
      />
      <div className={s.itemName}>{name}</div>
    </LocalizedProductLink>
  )
}
