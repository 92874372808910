import React from "react"

export default function RichText({ children }) {
  const reg = new RegExp(/(®)/, "gi")
  const parts = children.split(reg)
  return (
    <>
      {parts.map((str) =>
        str.match(reg) ? (
          <sup key={str}>{str}</sup>
        ) : (
          <React.Fragment key={str}>{str}</React.Fragment>
        )
      )}
    </>
  )
}
